<template>
  <v-card flat width="600" class="mx-auto mt-8">
    <v-form @submit.prevent="handleRegister">
      <v-card-title>
        <h2 class="red--text">Crear cuenta</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="user.name"
          prepend-icon="mdi-account-circle"
          placeholder="Nombre"
          :error-messages="this.errors['name']"
          color="red"
        />
        <v-text-field
          v-model="user.email"
          prepend-icon="mdi-email"
          placeholder="Correo electrónico"
          :error-messages="this.errors['email']"
          color="red"
        />
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          v-model="user.password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          placeholder="Contraseña"
          :error-messages="this.errors['password']"
          color="red"
        />
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          v-model="user.password_confirmation"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          placeholder="Confirmar contraseña"
          :error-messages="this.errors['password_confirmation']"
          color="red"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="handleRegister"
          class="mx-auto"
          color="grey darken-3"
          block
          rounded
          dark
        >Registrar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import register from "@/services/register";

export default {
  name: "Register",
  data: () => ({
    user: {
      name: null,
      email: null,
      password: null,
      password_confirmation: null
    },
    errors: {},
    showPassword: false
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push({ name: "Dashboard" });
    }
  },
  methods: {
    handleRegister() {
      let self = this;
      this.message = null;
      register
        .create(this.user)
        .then(() => {
          self.user = {
            name: null,
            email: null,
            password: null,
            password_confirmation: null
          };
          self.$router.push({ name: "Home" });
        })
        .catch(error => {
          if (error.response) {
            self.errors = error.response.data;
          }
        });
    }
  }
};
</script>
